import * as React from "react"
import { AvisoDePrivacidadComp } from "../components/AvisoDePrivacidad"
import Layout from '../components/Layout'
import SEO from "../components/seo"

const AvisoDePrivacidadPage = () => {
  return (
    <Layout>
      <SEO
        title="The Ocean Group"
        description="Ofrecemos oportunidades inmobiliarias en la costa de Oaxaca"
        works=''
      />

      <AvisoDePrivacidadComp/>

    </Layout>
  )
}

export default AvisoDePrivacidadPage
