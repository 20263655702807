import React from 'react';
import {Column, Columns, Container} from "bloomer";
import { ContAvisoDePrivacidad, GlobalStyleAvisoDePrivacidad, P } from './styles';
import FooterCopmp from "../Footer";

export const AvisoDePrivacidadComp = () => {
  return (
    <ContAvisoDePrivacidad>
      <GlobalStyleAvisoDePrivacidad/>
      <Container isFluid={false}>
        <Columns>
          <Column isSize={{mobile: 0, tablet: 0, desktop: 2, widescreen: 2}}>
            &nbsp;
          </Column>
          <Column isSize={{mobile: 12, tablet: 12, desktop: 8, widescreen: 8}}>
            <div>
              <P className="title-aviso">
                POLÍTICAS DE PRIVACIDAD DE DATOS
              </P>
              <P className="text-aviso">
                <b>The Ocean Group</b>, somos un Grupo de Desarrollo Inmobiliario comprometidos con la protección de los Datos
                Personales de nuestros clientes, por lo que el cumplimiento con nuestras obligaciones legales, es parte de
                nuestra cultura.
              </P>
              <P className="text-aviso">
                En ese sentido y en cumplimiento de la Ley de Protección de Datos Personales en Posesión de Particulares, <b>The
                  Ocean Group</b>, tiene la convicción de proteger la información personal proporcionada por sus clientes y es
                el responsable de su tratamiento cuando sean recabados directamente por nuestros colaboradores o a través de
                algún medio electrónico de su pertenencia. De tal modo que cuidamos la Privacidad de tus Datos Personales y su
                confidencialidad, mediante procedimientos y medidas de seguridad administrativas al interior de nuestra
                empresa, para brindarte la confianza de que tus Datos Personales están protegidos.
              </P>
              <P className="text-aviso">
                Es importante para nosotros, hacerte saber que valoramos mucho tu confianza, es por ello que en caso de que
                ocurra una modificación a los Avisos de Privacidad o exista alguna vulneración, <b>The Ocean Group</b> lo hará
                de tu conocimiento. Nos aseguraremos de que puedas conocer lo que ocurre con el Tratamiento de tus Datos
                Personales, con el fin de resguardar tus derechos. Asimismo, las transferencias o el uso de nuevas tecnologías
                para la recolección de tus Datos Personales, respetan en todo momento la confidencialidad y seguridad de los
                mismos, cumpliendo siempre con las finalidades del Aviso de Privacidad correspondiente.
              </P>
              <P className="text-aviso">
                <b>The Ocean Group</b>, recopila, almacena, utiliza, y, en general realiza actividades relacionadas con el
                Tratamiento de Datos Personales de los titulares de los mismos, que conforman la base de datos de usuarios de <b>The Ocean Group</b>, que le permitan interactuar activamente con los titulares de tal información,
                estableciendo canales de comunicación con sus clientes, ofrecimiento y asesoría personalizada para la
                adquisición de inmuebles, así como envío de publicidad, y todas aquellas relacionadas con su objeto social.
              </P>
              <P className="text-aviso">
                De tal manera, ponemos a tu disposición nuestro Aviso de Privacidad, en el cuál describimos y te informamos
                sobre el Tratamiento que damos a tus Datos Personales, así como los procedimientos para conocer y atender tus
                Derechos ARCO (Derechos de Acceso, Rectificación, Cancelación u Oposición, respecto de los Datos Personales
                que nos conciernen), a través de nuestro portal de internet: <b><a href="https://www.theoceangroup.mx" target="_blank">https://www.theoceangroup.mx</a></b>.
              </P>
              <P className="text-aviso">Te invitamos a consultarlo.</P>
              <P className="text-aviso-title">
                Responsable de los Datos Personales
              </P>
              <P className="text-aviso">
                "HYGGELIG DESARROLLO Y CONSTRUCCIÓN" Sociedad Anónima de Capital Variable, legalmente constituida bajo las
                leyes de la República Mexicana, ante la Fe del Notario Público Número 105 del Estado de Oaxaca y del
                Patrimonio Inmobiliario Federal, en la Villa de Zimatlán de Álvarez, Estado de Oaxaca, Escritura Pública que
                consta en el Volúmen número 27, Instrumento Público número 22,234; a quien en lo sucesivo se le denominará por
                su nombre comercial <b>THE OCEAN GROUP</b>.
              </P>
              <P className="text-aviso">
                Teniendo como domicilio, el ubicado en Calle 2ª Norte, Sector Reforma B, Puerto Escondido, San Pedro Mixtepec,
                Oaxaca, Código Postal 71980, tiene la responsabilidad de proteger la información personal proporcionada por
                sus clientes (en adelante "Datos Personales") y es el responsable de su Tratamiento, cuando sean recabados
                directamente por ella o a través de algún medio electrónico de su pertenencia.
              </P>
              <P className="text-aviso">
                Correo Electrónico de contacto: <b><a href="mailto:administración@theoceangroup.mx" target="_blank">administración@theoceangroup.mx</a></b>
              </P>
              <P className="text-aviso">Teléfono: <a href="tel:9545820489">954 582 0489</a>.</P>
              <P className="text-aviso-title">
                Tratamiento de Datos Personales
              </P>
              <P className="text-aviso">
                <b>The Ocean Group</b>, recabará directamente, los Datos Personales de los clientes para su uso comercial,
                divulgación y/o almacenamiento por cualquier medio para los fines abajo señalados.
                Nuestra empresa y nuestros colaboradores que lleguen a intervenir en cualquier fase del Tratamiento de Datos
                Personales, guardarán confidencialidad respecto de los mismos cuando tengan dicho carácter, conforme a las
                disposiciones legales aplicables en los Estados Unidos Mexicanos.
              </P>
              <P className="text-aviso-title">
                CONSENTIMIENTO
              </P>
              <P className="text-aviso">
                <b>The Ocean Group</b>, solicitará el consentimiento pleno y sin reservas de sus clientes para el Tratamiento
                de sus Datos Personales de conformidad con el presente Aviso de Privacidad y será necesaria la manifestación
                expresa del mismo para llevar a cabo su registro.
              </P>
              <P className="text-aviso-title">
                Datos Personales a recabar y su finalidad
              </P>
              <P className="text-aviso">
                <b>The Ocean Group</b>, es el responsable de recabar los Datos Personales mediante distintas formas: cuando el
                cliente acude a la oficina directamente, cuando los proporciona de manera telefónica o bien cuando visita
                nuestro sitio de internet.
              </P>
              <P className="text-aviso">
                Algunos de los Datos Personales que recabamos de manera enunciativa, más no limitativa, son los siguientes:
              </P>
              <P className="text-aviso">
                <ul>
                  <li>Nombre Completo</li>
                  <li>Nacionalidad</li>
                  <li>Sexo</li>
                  <li>Estado Civil</li>
                  <li>Email</li>
                  <li>Teléfono particular</li>
                  <li>Teléfono Celular</li>
                  <li>Domicilio</li>
                  <li>Profesión u oficio</li>
                  <li>Identificación Oficial</li>
                </ul>
              </P>
              <P className="text-aviso">
                Tus Datos Personales serán utilizados para diferentes finalidades como lo son:
              </P>
              <P className="text-aviso">
                <ul>
                  <li>Brindar atención y asesoría a nuestros clientes sobre los servicios ofertados por nuestra empresa.</li>
                  <li>Proveer servicios requeridos por nuestros clientes, siempre y cuando sean en congruencia con nuestro objeto social.</li>
                  <li>Enviarte ofertas o promociones relacionadas con nuestros servicios. </li>
                  <li>Ofrecer propiedades que The Ocean Group, considere de interés del Titular de los Datos Personales. </li>
                  <li>Establecer una vía de comunicación con nuestros clientes, Titulares de los Datos Personales.</li>
                  <li>Facilitar el acompañamiento personalizado en la búsqueda de un inmueble acorde a las especificaciones, necesidades e intereses de los titulares de los Datos Personales.</li>
                  <li>Ejecutar actividades de mercadeo y publicidad relacionadas con el objeto social de The Ocean Group. </li>
                </ul>
              </P>
              <P className="text-aviso-title">
                Datos personales sensibles
              </P>
              <P className="text-aviso">
                <b>The Ocean Group</b>, es consciente que en su labor diaria, ciertos datos proporcionados por nuestros clientes, revisten la calidad de Datos Sensibles, por esa razón, hemos aplicado al interior de nuestra empresa, las medidas adecuadas para que cuando este tipo de datos sean sometidos a tratamiento, se refuerce por nuestro personal, la confidencialidad y el cumplimiento de los principios que regulan el tratamiento de dichos datos, tal como lo establece la Ley; de igual manera estos datos sensibles únicamente serán recabados mediante la autorización expresa e informada de nuestros clientes, previo a la ejecución de actividades para su tratamiento.
              </P>
              <P className="text-aviso">
                <b>The Ocean Group</b>, considera como Datos Sensibles: "aquellos que puedan revelar aspectos como origen racial o étnico, estado de salud presente y futuro, información genética, creencias religiosas, filosóficas y/o morales, afiliación sindical, opiniones políticos, preferencia sexual".
              </P>
              <P className="text-aviso-title">
                Modificaciones al Aviso de Privacidad
              </P>
              <P className="text-aviso">
                <b>The Ocean Group</b>, se reserva el derecho de modificar en cualquier momento el contenido del presente Aviso de Privacidad. Cualquier cambio en el Aviso de Privacidad, será informado a sus clientes mediante nuestra página de internet.
              </P>
              <P className="text-aviso">
                Una vez publicado el presente Aviso de Privacidad, entrará en vigor automáticamente. Las modificaciones referidas anteriormente, estarán disponibles al público a través de nuestra página de Internet: <b><a href="https://www.theoceangroup.mx" target="_blank">https://www.theoceangroup.mx</a></b>.
              </P>
              <P className="text-aviso">
                <b>Medios para ejercer los Derechos de los Titulares de Datos Personales y Revocación del consentimiento.</b>
              </P>
              <P className="text-aviso">
                <b>The Ocean Group</b>,  es una empresa que en todo momento busca brindar el Tratamiento a los Datos Personales, de acuerdo a la legislación aplicable, de tal suerte que nuestros clientes pueden de manera irrenunciable, ejercer sus Derechos ARCO:
              </P>
              <P className="text-aviso">
                <ul>
                  <li>Acceso a sus Datos Personales;</li>
                  <li>Rectificación de sus Datos Personales cuando sean inexactos o incompletos;</li>
                  <li>Cancelación de sus Datos Personales;</li>
                  <li>Oponerse por causa legítima al Tratamiento de sus Datos Personales, y/o</li>
                  <li>Revocar su consentimiento para el tratamiento de sus Datos Personales en cualquier momento, a fin de que se deje de hacer uso de los mismos.</li>
                </ul>
              </P>
              <P className="text-aviso">
                El ejercicio de los derechos ARCO, deberá realizarse previa solicitud a nuestra Empresa y contener al menos lo siguiente:
              </P>
              <P className="text-aviso">
                <ul>
                  <li>El nombre del titular, domicilio y correo electrónico para comunicarle la respuesta a su solicitud;</li>
                  <li>Los documentos que acrediten la identidad o, en su caso, la representación legal del titular;</li>
                  <li>La descripción clara y precisa de los Datos Personales respecto de los que se busca ejercer alguno de los derechos antes mencionados (en el caso de rectificación se deberá indicar las modificaciones a realizar y aportar la documentación que sustente su petición);</li>
                  <li>Cualquier otro elemento o documento que facilite la localización de los datos personales.</li>
                </ul>
              </P>
              <P className="text-aviso">
                Para efecto de lo anterior, <b>The Ocean Group</b>, pone a disposición de los clientes el siguiente correo electrónico: <b><a href="mailto:administración@theoceangroup.mx" target="_blank">administración@theoceangroup.mx</a></b>.
              </P>
              <P className="text-aviso">
                <b>The Ocean Group</b>, dará respuesta a la solicitud de nuestros clientes en un plazo no mayor a 15 días hábiles contados a partir de la fecha en que reciba la petición correspondiente, a través del correo electrónico que haya sido proporcionado para tal efecto.
              </P>
              <P className="text-aviso">El ejercicio por parte de los clientes de los derechos antes mencionados deberá sujetarse a las leyes y reglamentos aplicables vigentes en México.</P>
            </div>
          </Column>
          <Column isSize={{mobile: 0, tablet: 0, desktop: 2, widescreen: 2}}>
            &nbsp;
          </Column>
        </Columns>
      </Container>
      <FooterCopmp/>
    </ContAvisoDePrivacidad>
  )
}
