import styled, { createGlobalStyle }  from 'styled-components'
import {GlobalFonts, GlobalColors, GlobalBackgroundColors} from "../../styles/GlobalStyles";
import BackBetas from '../../images/back-betas.svg';

export const GlobalStyleAvisoDePrivacidad = createGlobalStyle`
  .text-aviso-title{
    font: 18px ${GlobalFonts.fontBold} !important;
  }
`

export const ContAvisoDePrivacidad = styled.div`
  width: 100%;
  background-color: ${GlobalBackgroundColors.bgPrimary};
  /* 
  background-image: url(${BackBetas});
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: right;
  background-position-y: bottom; */
`

export const P = styled.p`
  margin-bottom: 20px;
  font: 14px ${GlobalFonts.fontRegular};
  line-height: 18px;
  color: ${GlobalColors.colorPrimary};

  & a{
    font: 14px ${GlobalFonts.fontBold};
    line-height: 18px;
    color: ${GlobalColors.colorPrimary};
  }
  & a:hover{
    font: 14px ${GlobalFonts.fontBold};
    line-height: 18px;
    color: ${GlobalColors.colorLinkHover};
  }
`